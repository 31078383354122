import { Auth } from 'aws-amplify'

const authDevConfig = {
  userPoolId: 'eu-west-1_u4JXsFfH0',
  userPoolWebClientId: '7gofj8iti461kf9uroj2gsf8t1',
  region: 'eu-west-1',
  mandatorySignIn: true,
  identityPoolRegion: 'eu-west-1',
}

const authProdConfig = {
  userPoolId: 'eu-west-2_FRQ6utzUq',
  userPoolWebClientId: '5im0dkilnjtqe69sbmbksqjunk',
  region: 'eu-west-2',
  identityPoolId: 'eu-west-2:5e73b836-49cc-4a58-b87c-69e8178c2ac6',
  mandatorySignIn: true,
  identityPoolRegion: 'eu-west-2',
}

export const authConfig =
  process.env.REACT_APP_WEBSITE_STAGE === 'production'
    ? authProdConfig
    : authDevConfig

class AuthClass {
  signIn = async (email, password) => {
    await Auth.signIn(email, password)
    const user = await Auth.currentAuthenticatedUser()
    const session = await Auth.currentSession()
    localStorage.id_token = session.getIdToken().getJwtToken()
    localStorage.access_token = session.getAccessToken().getJwtToken()
    delete user.attributes.sub
    const userInfo = {
      ...user.attributes,
      username: user.username,
    }
    localStorage.user_info = JSON.stringify(userInfo)
    return userInfo
  }
  signUp = async (email, password) => {
    await Auth.signUp({ username: email, password })
  }
  isAuthenticated = () => !!localStorage.id_token
  getIdToken = () => {
    return localStorage.id_token
  }
  getAccessToken = () => localStorage.access_token
  signOut = async (history) => {
    try {
      if (Auth.user) {
        const user = await Auth.currentAuthenticatedUser()
        if (user.getSignInUserSession()) {
          user.signOut()
        }
      }
      localStorage.clear()
    } catch (e) {
      console.error(e)
    }
    history.push('/login')
  }
}

export default new AuthClass()
