import * as actionTypes from '../actions';

const clientsReducer = (state = [], action) => {

  switch (action.type) {
    case actionTypes.GET_CLIENTS:
      return action.payload;
    case actionTypes.ADD_CLIENT:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default clientsReducer;