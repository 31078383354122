export default {
  defaultPath: '/contents',
  basename: '/', // only at build time to set, like ///mintone/react/default
  layout: 'vertical', // vertical, horizontal
  collapseMenu: false, // mini-menu
  layoutType: 'menu-light', // menu-dark, menu-light
  headerBackColor: '', // header-blue, header-red, header-purple, header-info, header-dark
  fullWidthLayout: false,
  navFixedLayout: true,
  headerFixedLayout: true,
  defaultContentUser: 'hello+easypeasy@easypeasyapp.com'
};
