import { newApi, handleError } from '../../api'

export const GET_CLIENTS = 'GET_CLIENTS'
export const ADD_CLIENT = 'ADD_CLIENT'

export const getClients = (history) => async (dispatch) => {
  try {
    const response = await newApi.get('/clients')
    dispatch({ type: GET_CLIENTS, payload: response.data })
  } catch (error) {
    handleError({ error, history })
  }
}

export const addClient = (client, history) => async (dispatch) => {
  try {
    const response = await newApi.post('/clients', client)
    dispatch({ type: ADD_CLIENT, payload: response.data })
    history.goBack()
  } catch (error) {
    handleError({ error, history })
  }
}
