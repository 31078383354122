import * as actionTypes from '../actions';

const invitationCodesReducer = (state = [], action) => {

  switch (action.type) {
    case actionTypes.GET_INVITE_CODES:
      return action.payload;

    case actionTypes.ADD_INVITE_CODE:
      return [...state, action.payload.inviteCode];
    
    default:
      return state;
  }
};

export default invitationCodesReducer;
