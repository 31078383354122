import * as actionTypes from '../actions';

const initialState = {
  currentUser: null,
  data: {
    users: [],
    moments: [],
    contents: [],
    numContents: [],
    ageRanges: [],
    tags: [],
  },
};

const dataReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        currentUser: action.data,
      };
    case actionTypes.STORE_DATA:
      return {
        ...state,
        data: {
          users: action.data.users || state.data.users,
          moments: action.data.moments || state.data.moments,
          contents: action.data.contents || state.data.contents,
          numContents: action.data.numContents || state.data.numContents,
          ageRanges: action.data.ageRanges || state.data.ageRanges,
          tags: action.data.tags || state.data.tags,
        },
      };
    default:
      return state;
  }
};

export default dataReducer;
