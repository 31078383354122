import React from 'react';

const SignIn = React.lazy(() => import('./Pages/SignIn'));
const SignOut = React.lazy(() => import('./Pages/SignOut'));
const Error404 = React.lazy(() => import('./Pages/Maintenance/Error404'));

const route = [
  { path: '/login', exact: true, name: 'Signin', component: SignIn },
  { path: '/logout', exact: true, name: 'Signout', component: SignOut },
  { path: '/error-404', exact: true, name: 'Error 404', component: Error404 },
];
export default route;
