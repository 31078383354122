import axios from "axios";
import { NotificationManager } from "react-notifications";
import { Auth } from "aws-amplify";

const baseURL =
  process.env.REACT_APP_WEBSITE_STAGE === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const version = process.env.REACT_APP_VERSION;

const getCommonApiUrl = () => {
  const commonApi =
    process.env.REACT_APP_WEBSITE_STAGE === "local"
      ? `${baseURL}:4002`
      : `${baseURL}`;

  return `${commonApi}/common-${version}`;
};

const getAdminApiUrl = () => {
  const adminApi =
    process.env.REACT_APP_WEBSITE_STAGE === "local"
      ? `${baseURL}:4000`
      : `${baseURL}`;

  return `${adminApi}/admin-${version}`;
};

export const API_TYPE = {
  ADMIN_API: "ADMIN_API",
  COMMON_API: "COMMON_API",
};
export const apiClient = (apiType) => {
  return axios.create({
    baseURL:
      apiType === API_TYPE.COMMON_API ? getCommonApiUrl() : getAdminApiUrl(),
    withCredentials: false, // This is the default
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    timeout: 10000,
  });
};

export const oldCommonApi = apiClient(API_TYPE.COMMON_API);
oldCommonApi.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${session
      .getIdToken()
      .getJwtToken()}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const oldAdminApi = apiClient();
oldAdminApi.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${session
      .getIdToken()
      .getJwtToken()}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const newApi = axios.create({
  baseURL: `${process.env.REACT_APP_NEW_API_URL}/v1`,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 100000,
});

newApi.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${session
      .getIdToken()
      .getJwtToken()}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const handleError = ({ error, history }) => {
  console.error("network error: ", error);
  NotificationManager.error(
    error.response
      ? error.response.data.message || error.message
      : error.message
  );
  if (
    (error.request && error.request.status === 401) ||
    (error.response && error.response.status === 401)
  ) {
    // history.push('/logout')
  }
};
