import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { loadState, saveState } from './localStorage';

export const store = process.env.REACT_APP_WEBSITE_STAGE !== 'production'
  ? createStore(reducer, loadState(), applyMiddleware(thunk,logger), )
  : createStore(reducer, loadState(), applyMiddleware(thunk));

  store.subscribe(()=>{
    saveState(
      store.getState());
  });
