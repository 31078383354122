import { combineReducers } from 'redux';

import uiReducer from './uiReducer';
import dataReducer from './dataReducer';
import clientsReducer from './clientsReducer';
import invitationCodesReducer from './invitationCodesReducer';

export const reducer = combineReducers({
  ui: uiReducer,
  clients: clientsReducer,
  data: dataReducer,
  invitationCodes: invitationCodesReducer,
});

export default reducer;