import { newApi, handleError } from '../../api'

export const GET_INVITE_CODES = 'GET_INVITE_CODES'
export const GET_INVITE_CODES_TYPES = 'GET_INVITE_CODES_TYPES'
export const ADD_INVITE_CODE = 'ADD_INVITE_CODE'

export const getInviteCodes = (history) => async (dispatch) => {
  try {
    const response = await newApi.get('/invite-codes')
    dispatch({ type: GET_INVITE_CODES, payload: response.data })
  } catch (error) {
    handleError({ error, history })
  }
}

export const getInviteCodeTypes = (history) => async (dispatch) => {
  try {
    const response = await newApi.get('/invite-code-types')
    dispatch({ type: GET_INVITE_CODES_TYPES, payload: response.data })
  } catch (error) {
    handleError({ error, history })
  }
}

export const addInviteCode = (client, code, history) => async (dispatch) => {
  try {
    const response = await newApi.post(`/clients/${client}/invite-codes`, code)
    dispatch({ type: ADD_INVITE_CODE, payload: response.data })
    history.push('/invitation-codes')
  } catch (error) {
    handleError({ error, history })
  }
}

export const editInviteCode =
  (client, id, code, history) => async (dispatch) => {
    try {
      const response = await newApi.put(
        `/clients/${client}/invite-codes/${id}`,
        code,
      )
      dispatch({ type: ADD_INVITE_CODE, payload: response.data })
      history.push('/invitation-codes')
    } catch (error) {
      handleError({ error, history })
    }
  }

export const adaptInviteCode =
  (client, id, code, history) => async (dispatch) => {
    try {
      const response = await newApi.post(
        `/clients/${client}/invite-codes/${id}/adapt`,
        code,
      )
      dispatch({ type: ADD_INVITE_CODE, payload: response.data })
      history.push('/invitation-codes')
    } catch (error) {
      handleError({ error, history })
    }
  }
