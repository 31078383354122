import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../route';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import CognitoAuth from '../CognitoAuth';

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader,
});

class App extends Component {
  render() {
    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={props => (
            <route.component {...props} />
          )}/>
      ) : null;
    });

    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader/>}>
            <Switch>
              {menu}
              {CognitoAuth.isAuthenticated() ? <Route path="/" component={AdminLayout}/> : <Redirect to={'/logout'}/>}
            </Switch>
          </Suspense>
        </ScrollToTop>
        <NotificationContainer/>
      </Aux>
    );
  }
}

export default App;
